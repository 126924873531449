import React, { useState, useEffect } from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import RightSidebar from "../components/RightSidebar.js";
import TheTumbler from "../components/TheTumbler";
import sampleSize from "lodash/sampleSize";

const allRecommendations = require("./recommendations.json");

const randomAllRecommendations = sampleSize(
  allRecommendations,
  allRecommendations.length
);

const RecommendationsPage = () => {
  const [layoutAlt, setlayoutAlt] = useState(false);

  const theSwitch = (e) => {
    // setlayoutAlt(layoutAlt != "mini" ? "mini" : "default");
    setlayoutAlt(e);
    // console.log("🚀 ~ ~ e", e);
  };

  useEffect(() => {
    setlayoutAlt("default");
  }, []);

  // useEffect(() => {
  //   setlayoutAlt("default");
  //   console.log("🚀🚀🚀🚀🚀🚀🚀🚀  ", layoutAlt);
  // });

  //    componentDidMount() {
  //   setTimeout(() => {
  //     this.loadtheWeathersAxios();
  //   }, 100);
  // }

  /*
  var axios = require("axios").default;

  var options = {
    method: "GET",
    url: "https://api.newscatcherapi.com/v2/search",
    params: {
      q: "49ers",
      lang: "en",
      sort_by: "date",
      page: "1",
      page_size: "100",
      ranked_only: "True",
    },
    headers: {
      "x-api-key": "FlkN3PkJMzK3sQEhD70rtnSMz-MXPiJhAUPRqlIvXt0",
    },
  };

  axios
    .request(options)
    .then(function (response) {
      console.log(" ====> ", response.data);
    })
    .catch(function (error) {
      console.error(error);
    });

  */

  return (
    <>
      <Head
        title="Our Recommendations"
        description="Our personal recommendations the top fusion, fish, authentic tacos in Austin"
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Our Taco Restaurant Recommendations</h1>

              <section className="promo-top-recommendations">
                Welcome to our recommendations overview page which lists our
                humble reviews on some of our favorite spots we have visited. We
                will be frequently adding to our list as we combine our favorite
                list of tacos all across Austin. Our goal is not only to find
                the best choices, but also keep our taste buds guessing with
                unique flavors and fusion options. Our below reviews are{" "}
                <u>not</u> in any particular order.
              </section>

              <div className="switcher">
                <span
                  className={layoutAlt == "default" ? "active" : "not"}
                  onClick={() => theSwitch("default")}
                >
                  <i className="fa-solid fa-bars"></i>
                  {/* Default */}
                </span>
                <span
                  className={layoutAlt != "default" ? "active" : "not"}
                  onClick={() => theSwitch("mini")}
                >
                  <i className="fa-solid fa-grip-vertical"></i>
                  {/* Mini */}
                </span>
              </div>

              <div
                className={`${
                  layoutAlt == "mini"
                    ? `promo-recommendation-wrapper promo-recommendation-wrapper--mini`
                    : `promo-recommendation-wrapper`
                }`}
              >
                {randomAllRecommendations.map((node, i) => {
                  return (
                    <section className="promo-recommendations" key={i}>
                      <div className="img">
                        <a href={node.link}>
                          <img src={node.img} />
                        </a>
                      </div>
                      <div className="contents">
                        <div className="title">
                          <a href={node.link}>{node.title}</a>
                        </div>
                        <div className="descr">{node.desc}</div>
                        <div className="we-like">
                          {node.whatWeLike.map((el, p) => {
                            return (
                              <span key={p}>
                                {el} <i>*</i>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                  );
                })}
              </div>
            </div>

            <div className="right-sidebar">
              <TheTumbler></TheTumbler>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default RecommendationsPage;
